const isElementInViewport = element => {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

const showImage = () => {
  const prefix = '.glightbox-';
  const num_glightboxes = 10;
  let selectors = [];

  for (let i=1; i<=num_glightboxes; i++) {
    selectors.push(`${prefix}${i}`);
  }

  document.querySelectorAll(selectors.toString()).forEach((element, idx) => {
    if (isElementInViewport(element)) {
      element.classList.add('opaque');
    }
  });
};

export const initAnimationEvents = () => {
  window.addEventListener('load', showImage);
  window.addEventListener('scroll', showImage);
};
